/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',

  // The operator can accept or decline the offer on behalf of the provider
  // OPERATOR_ACCEPT: 'transition/operator-accept',
  // OPERATOR_DECLINE: 'transition/operator-decline',

  SECURITY_DEPOSIT_PAYMENT: 'transition/security-deposit-payment',
  AGREEMENT_SIGN_INITIATION: 'transition/agreement-sign-initiation',
  AGREEMENT_SIGN_BY_RENTER: 'transition/agreement-sign-by-renter',
  SEND_REMINDER_AGREEMENT_SIGN_BY_RENTER: 'transition/send-reminder-agreement-sign-by-renter',
  AGREEMENT_SIGN_BY_RENTER_AFTER_REMINDER: 'transition/agreement-sign-by-renter-after-reminder',
  ADD_PICKUP_DROPOFF_NOTES: 'transition/add-pickup-dropoff-notes',
  SEND_REMINDER_ADD_PICKUP_DROPOFF_NOTES: 'transition/send-reminder-add-pickup-dropoff-notes',
  ADD_PICKUP_DROPOFF_NOTES_AFTER_REMINDER: 'transition/add-pickup-dropoff-notes-after-reminder',
  KART_RECEIVED_FROM_PROVIDER: 'transition/kart-received-from-provider',
  KART_RECEIVED_FROM_PROVIDER_WITH_DISCREPANCIES:
    'transition/kart-received-from-provider-with-discrepancies',
  KART_RETURNED_TO_PROVIDER: 'transition/kart-returned-to-provider',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CUSTOMER_CANCEL: 'transition/customer-cancel',
  PROVIDER_CANCEL_AFTER_ACCEPTANCE: 'transition/provider-cancel-after-acceptance',
  PROVIDER_CANCEL_AFTER_SECURITY_DEPOSIT: 'transition/provider-cancel-after-security-deposit',
  CUSTOMER_CANCEL_AFTER_SECURITY_DEPOSIT: 'transition/customer-cancel-after-security-deposit',
  PROVIDER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION:
    'transition/provider-cancel-after-agreement-sign-initiation',
  CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION:
    'transition/customer-cancel-after-agreement-sign-initiation',
  PROVIDER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER:
    'transition/provider-cancel-after-sent-reminder-agreement-sign-by-renter',
  CUSTOMER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER:
    'transition/customer-cancel-after-sent-reminder-agreement-sign-by-renter',
  PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED: 'transition/provider-cancel-after-agreement-signed',
  CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED: 'transition/customer-cancel-after-agreement-signed',
  PROVIDER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES:
    'transition/provider-cancel-after-sent-reminder-add-pickup-dropoff-notes',
  CUSTOMER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES:
    'transition/customer-cancel-after-sent-reminder-add-pickup-dropoff-notes',
  PROVIDER_CANCEL_AFTER_NOTES_ADDED: 'transition/provider-cancel-after-notes-added',
  CUSTOMER_CANCEL_AFTER_NOTES_ADDED: 'transition/customer-cancel-after-notes-added',
  ADDITIONAL_FEES_REQUEST: 'transition/additional-fees-request',
  ADDITIONAL_FEES_ACCEPTED: 'transition/additional-fees-accepted',
  BOOKING_EXPIRE: 'transition/booking-expire',
  ADDITIONAL_FEES_DEPOSIT: 'transition/additional-fees-deposit',
  PROVIDER_PAYOUT_INITIATION: 'transition/provider-payout-initiation',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  ADDITIONAL_FEES_DECLINE: 'transition/additional-fees-decline',
  DISPUTE_REFUND_INITIATION: 'transition/dispute-refund-initiation',
  DISPUTE_REFUND_DEPOSIT: 'transition/dispute-refund-deposit',
  DISPUTE_REJECTED: 'transition/dispute-rejected',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  SEND_REMINDER_DEPOSIT_SECURITY_AMOUNT: 'transition/send-reminder-deposit-security-amount',
  DEPOSIT_SECURITY_AMOUNT_AFTER_REMINDER: 'transition/deposit-security-amount-after-reminder',
  BOOKING_EXPIRE_AFTER_REMINDER: 'transition/booking-expire-after-reminder',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  SECURITY_DEPOSITED: 'security-deposited',
  AGREEMENT_SIGN_INITIATED: 'agreement-sign-initiated',
  SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER: 'sent-reminder-agreement-sign-by-renter',
  AGREEMENT_SIGNED: 'agreement-signed',
  SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES: 'sent-reminder-add-pickup-dropoff-notes',
  NOTES_ADDED: 'notes-added',
  KART_DELIVERED: 'kart-delivered',
  KART_RETURNED: 'kart-returned',
  ADDITIONAL_FEES_REQUESTED: 'additional-fees-requested',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  DELIVERED: 'delivered',
  DISPUTED: 'disputed',
  DISPUTE_REFUND_INITIATED: 'dispute-refund-initiated',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  BOOKING_EXPIRED: 'booking-expired',
  ADDITIONAL_FEES_ACCEPTED: 'additional-fees-accepted',
  PAYOUT_INITIATED: 'payout-initiated',
  SENT_REMINDER_DEPOSIT_SECURITY_AMOUNT: 'sent-reminder-deposit-security-amount',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.CUSTOMER_CANCEL]: states.CANCELED,
      },
    },

    [states.DECLINED]: {},
    [states.EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.PROVIDER_CANCEL_AFTER_ACCEPTANCE]: states.CANCELED,
        [transitions.SECURITY_DEPOSIT_PAYMENT]: states.SECURITY_DEPOSITED,
        [transitions.BOOKING_EXPIRE]: states.BOOKING_EXPIRED,
        [transitions.SEND_REMINDER_DEPOSIT_SECURITY_AMOUNT]:
          states.SENT_REMINDER_DEPOSIT_SECURITY_AMOUNT,
      },
    },

    [states.SENT_REMINDER_DEPOSIT_SECURITY_AMOUNT]: {
      on: {
        [transitions.BOOKING_EXPIRE_AFTER_REMINDER]: states.BOOKING_EXPIRED,
        [transitions.DEPOSIT_SECURITY_AMOUNT_AFTER_REMINDER]: states.SECURITY_DEPOSITED,
      },
    },

    [states.BOOKING_EXPIRED]: {},
    [states.SECURITY_DEPOSITED]: {
      on: {
        [transitions.AGREEMENT_SIGN_INITIATION]: states.AGREEMENT_SIGN_INITIATED,
        [transitions.PROVIDER_CANCEL_AFTER_SECURITY_DEPOSIT]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_SECURITY_DEPOSIT]: states.CANCELED,
      },
    },

    [states.AGREEMENT_SIGN_INITIATED]: {
      on: {
        [transitions.AGREEMENT_SIGN_BY_RENTER]: states.AGREEMENT_SIGNED,
        [transitions.SEND_REMINDER_AGREEMENT_SIGN_BY_RENTER]:
          states.SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER,
        [transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION]: states.CANCELED,
      },
    },

    [states.SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER]: {
      on: {
        [transitions.AGREEMENT_SIGN_BY_RENTER_AFTER_REMINDER]: states.AGREEMENT_SIGNED,
        [transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER]: states.CANCELED,
      },
    },

    [states.AGREEMENT_SIGNED]: {
      on: {
        [transitions.ADD_PICKUP_DROPOFF_NOTES]: states.NOTES_ADDED,
        [transitions.SEND_REMINDER_ADD_PICKUP_DROPOFF_NOTES]:
          states.SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
        [transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED]: states.CANCELED,
      },
    },

    [states.SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES]: {
      on: {
        [transitions.ADD_PICKUP_DROPOFF_NOTES_AFTER_REMINDER]: states.NOTES_ADDED,
        [transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES]: states.CANCELED,
      },
    },

    [states.NOTES_ADDED]: {
      on: {
        [transitions.KART_RECEIVED_FROM_PROVIDER]: states.KART_DELIVERED,
        [transitions.PROVIDER_CANCEL_AFTER_NOTES_ADDED]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_AFTER_NOTES_ADDED]: states.CANCELED,
        [transitions.KART_RECEIVED_FROM_PROVIDER_WITH_DISCREPANCIES]: states.KART_DELIVERED,
      },
    },

    [states.KART_DELIVERED]: {
      on: {
        [transitions.KART_RETURNED_TO_PROVIDER]: states.KART_RETURNED,
      },
    },

    [states.KART_RETURNED]: {
      on: {
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.ADDITIONAL_FEES_REQUEST]: states.ADDITIONAL_FEES_REQUESTED,
      },
    },

    [states.ADDITIONAL_FEES_REQUESTED]: {
      on: {
        [transitions.ADDITIONAL_FEES_ACCEPTED]: states.ADDITIONAL_FEES_ACCEPTED,
        [transitions.ADDITIONAL_FEES_DECLINE]: states.DISPUTED,
      },
    },

    [states.ADDITIONAL_FEES_ACCEPTED]: {
      on: {
        [transitions.ADDITIONAL_FEES_DEPOSIT]: states.DELIVERED,
      },
    },

    [states.DISPUTED]: {
      on: {
        [transitions.DISPUTE_REFUND_INITIATION]: states.DISPUTE_REFUND_INITIATED,
        [transitions.DISPUTE_REJECTED]: states.DELIVERED,
      },
    },

    [states.DISPUTE_REFUND_INITIATED]: {
      on: {
        [transitions.DISPUTE_REFUND_DEPOSIT]: states.DELIVERED,
      },
    },

    [states.CANCELED]: {
      on: {
        [transitions.PROVIDER_PAYOUT_INITIATION]: states.PAYOUT_INITIATED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.OPERATOR_ACCEPT,
    transitions.SECURITY_DEPOSIT_PAYMENT,
    transitions.AGREEMENT_SIGN_INITIATION,
    transitions.AGREEMENT_SIGN_BY_RENTER,
    transitions.SEND_REMINDER_AGREEMENT_SIGN_BY_RENTER,
    transitions.AGREEMENT_SIGN_BY_RENTER_AFTER_REMINDER,
    transitions.ADD_PICKUP_DROPOFF_NOTES,
    transitions.SEND_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
    transitions.ADD_PICKUP_DROPOFF_NOTES_AFTER_REMINDER,
    transitions.KART_RECEIVED_FROM_PROVIDER,
    transitions.KART_RETURNED_TO_PROVIDER,
    transitions.CUSTOMER_CANCEL,
    transitions.PROVIDER_CANCEL_AFTER_SECURITY_DEPOSIT,
    transitions.CUSTOMER_CANCEL_AFTER_SECURITY_DEPOSIT,
    transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION,
    transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION,
    transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER,
    transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER,
    transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED,
    transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED,
    transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
    transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
    transitions.PROVIDER_CANCEL_AFTER_NOTES_ADDED,
    transitions.CUSTOMER_CANCEL_AFTER_NOTES_ADDED,
    transitions.ADDITIONAL_FEES_REQUEST,
    transitions.ADDITIONAL_FEES_DECLINE,
    transitions.KART_RECEIVED_FROM_PROVIDER_WITH_DISCREPANCIES,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.PROVIDER_CANCEL_AFTER_ACCEPTANCE,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
    transition
  );
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE,
    transitions.DECLINE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
